import { ProductDto } from './product.dto';

export class Product {
    id: string;
    no: string;
    no2: string;
    description: string;
    description2: string;
    categoryCode: string;
    manufacturerCode: string;
    vatRate: number;
    discount: number;
    quantity: number;
    imageId: string;
    isPromo: boolean;
    isNew: boolean;
    defaultUnit: string;
    price: number;
    discountPrice: number;
    gtin: string;

    constructor(productDto: ProductDto) {
        this.id = productDto.item_id;
        this.no = productDto.item_no;
        this.no2 = productDto.item_no2;
        this.description = productDto.item_description;
        this.description2 = productDto.item_description2;
        this.categoryCode = productDto.item_category_code;
        this.manufacturerCode = productDto.item_manufacturer_code;
        this.vatRate = productDto.item_vat_rate;
        this.discount = productDto.item_discount;
        this.quantity = productDto.inventory_on_stock && productDto.inventory_on_stock < 0 ? 0 : productDto.inventory_on_stock;
        this.imageId = productDto.image_url;
        this.isPromo = productDto.item_promo;
        this.defaultUnit = productDto.item_base_uo_m;
        this.price = Math.round(100 * productDto.sales_price);
        this.discountPrice = Math.round(100 * productDto.sales_price * (1 - productDto.item_discount / 100));
        this.gtin = productDto.gtin;
        this.isNew = productDto.item_new_in;
    }
}
